import axios from "axios";
import {EMPLOYEE_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import HireIndividualEmployee from "@/dto/payroll/HireIndividualEmployee";
import {UpdateEmployeePayload} from "@/dto/payroll/EmployeeDto";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import QueryUtils from "@/utils/QueryUtils";
import Pageable from "@/dto/Pageable";

class EmployeeService {

    public getEmployeeById(employeeId: number | string) {
        return axios.get(`${EMPLOYEE_ENDPOINT}/${employeeId}`, {headers: authHeader()})
    }

    public findByFilter(filter: EmployeeFilter) {
        const queryString = QueryUtils.objectToQueryString(filter);
        return axios.get<Pageable<EmployeeDto>>(`${EMPLOYEE_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

    public registerStaffer(req: HireIndividualEmployee) {
        return axios.post(`${EMPLOYEE_ENDPOINT}`, req, {headers: authHeader()})
    }

    updateContractor(payload: UpdateEmployeePayload) {
        return axios.put(`${EMPLOYEE_ENDPOINT}`, payload, {headers: authHeader()})
    }

    terminateContractor(payload: TerminationPayloadDTO) {
        return axios.put(`${EMPLOYEE_ENDPOINT}/terminate`, payload, {headers: authHeader()})
    }

    deleteContractor(employeeId: number) {
        return axios.delete(`${EMPLOYEE_ENDPOINT}/${employeeId}`, {headers: authHeader()})
    }
}

export default new EmployeeService();