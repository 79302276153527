export default class CreatePersonRequestDTO {
    public email: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public ssn: string | null = null;

    public resident = true;

    public client = true;

    public nationalTin: string | null = null;

    public phone: string | null = null;

    public isMerge: boolean | null = null;

}