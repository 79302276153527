import {CounterpartyType} from "@/constants/CounterpartyType";
import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {DateTime} from "luxon";

export default class RegisterContractorPayload {
    employerId: number | null = null;
    contractorId: number | null = null;
    branchIds: Array<number> = [];
    employmentStart: Date | null = null;
    type: CounterpartyType = CounterpartyType.COMPANY;
    payType: PayType | null = null;
    payPeriodType: PayPeriodType | null = null;
    rate: number | null = null;
    zoneId = DateTime.local().zoneName
}